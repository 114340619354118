import React from "react"
import Layout, { ThreeCols } from "../components/layout"
import {
  Button,
  Avantages,
  Spacer,
  Title,
  Input,
} from "../components/banners"
import { validEmail, validString } from "../utils/utils"
import { login } from "../actions"
import { Submitter } from "../components/submitter"
import { useForm } from "../hooks"
import styled from 'styled-components'

const Form = () => {
  const validate = (s, _errors) => {
    if (!validEmail(s.email)) {
      _errors.email = "Veuillez saisir une adresse email valide."
    }
    if (!validString(s.pwd)) {
      _errors.pwd = "Veuillez saisir votre mot de passe."
    }
  }

  const [u, submit, errors] = useForm(validate, login)

  return (
      <div style={{ border: "1px solid black", padding: "15px 30px" }}>
    <Title>Nouveau client ?</Title>
    <Button to="/signup" style={{ width: "100%" }}>
    Inscription
    </Button>
    <Spacer v="4rem" />
    <form onSubmit={(e) => {e.preventDefault(); return false}}>
    <Title>J'ai déjà un compte</Title>
    <Input label="Email" {...u("email")} />
    <Spacer v="1.5rem" />
    <Input label="Mot de passe" type="password" {...u("pwd")} />
    <Spacer v="1.5rem" />
    <Submitter error={errors.top} action={submit} style={{width: '100%'}}>
    Connexion
    </Submitter>
    </form>
    </div>
  )
}

export default ({ location }) => {
  console.log(location.state)
  return (
    <Layout>
      <ThreeCols>
        <Form />>
      </ThreeCols>
      <Spacer v="6rem" />
      <Avantages />
    </Layout>
  )
}
